import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import { isEmpty, get, merge, filter, set } from 'lodash';
import MappingList from '../Actions/Configuration/ExternalServiceCall/MappingList';

function DocumentData({
  onChange, actions, workguide, language
}) {
  const mapping = get(workguide, `documentRawData.${language}.params.mapping`);
  const [openEditor, setOpenEditor] = useState(!isEmpty(mapping));

  const onMappingChange = (mapping) => {
    onChange('documentRawData', 'documentRawData', mapping);
  };

  const deleteDocumentRelevantData = useCallback(() => {
    const components = get(workguide, 'components', []);

    // Remove documentRawData for the specified language
    set(workguide, `documentRawData.${language}`, {});

    // Filter out the not relevant components
    if (isEmpty(get(workguide, 'documentRawData.de')) && get(workguide, 'documentRawData.fr')) {
      // Remove the ConsultantSelect component
      const updatedComponents = filter(components, (component) => {
        return !(
          (component.component === 'ConsultantSelect' && get(component, 'props.type') === 'documentGenerateConsultant')
          || (component.component === 'CodeSelect' && get(component, 'props.id') === 'documentLanguage')
        );
      });

      // Update the components array with the filtered components
      set(workguide, 'components', updatedComponents);
    }

    // Dispatch the update action with the modified workguide
    actions.updateWorkguideRequest(workguide);
  }, [actions, workguide, language]);

  const updateWorkguideConsultantSelect = useCallback(() => {
    // Check if 'ConsultantSelect' already exists in the components array
    const consultantSelectExists = get(workguide, 'components', []).some(
      (cmp) => cmp.component === 'ConsultantSelect'
        && get(cmp, 'component.props.type') === 'documentGenerateConsultant'
    );

    if (!consultantSelectExists) {
      // Add the new ConsultantSelect component if it doesn't exist
      const updatedWorkguide = merge(workguide, {
        components: [
          {
            component: 'ConsultantSelect',
            order: 1,
            dependencies: [],
            props: {
              id: 'documentConsultant',
              name: 'documentConsultant',
              title: {
                de: 'Kundenberater für Dokumentenerstellung (DE)',
                fr: 'Kundenberater für Dokumentenerstellung (FR)',
                en: 'Kundenberater für Dokumentenerstellung',
              },
              type: 'documentGenerateConsultant',
              colTitles: [],
              multi: false,
              options: [],
              required: true,
              dynamicProps: [],
              defaults: {
                de: ['current'],
                fr: ['current'],
                en: ['current'],
              },
            },
          },
          {
            component: 'CodeSelect',
            order: 2,
            props: {
              id: 'documentLanguage',
              name: 'documentLanguage',
              type: 'input',
              options: [],
              multi: false,
              title: {
                de: 'Dokumentensprache',
                fr: 'Langue du document',
                en: 'Document language'
              },
              group: 'language',
              defaults: {
                de: [
                  'jmes:customer.language.id'
                ],
                fr: [
                  'jmes:customer.language.id'
                ]
              }
            }
          }
        ],
      });

      // Dispatch the update action with the modified workguide
      actions.updateWorkguideRequest(updatedWorkguide);
    }
  }, [actions, workguide]);

  const onToggle = useCallback(() => {
    setOpenEditor(!openEditor);
    if (!openEditor) {
      updateWorkguideConsultantSelect();
    } else {
      deleteDocumentRelevantData();
    }
  }, [openEditor, updateWorkguideConsultantSelect, deleteDocumentRelevantData]);

  return (
    <div className="document-data--container">
      <Toggle checked={openEditor} onChange={onToggle} />
      {openEditor && (
        <MappingList edit mapping={mapping} onChange={onMappingChange} />
      )}
    </div>
  );
}

DocumentData.propTypes = {
  onChange: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  workguide: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};

export default DocumentData;
