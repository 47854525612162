import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Button,
  Row
} from 'react-bootstrap';
import Toggle from 'react-toggle';
import 'react-rangeslider/lib/index.css';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _, { get, isNil } from 'lodash';

import * as ConfigurationForms from './Configuration';
import DependencyList from './Dependency/List';
import FieldValidations from './Validation/FieldValidations';
import ComponentSize from './Configuration/ComponentSize';

function getConfigurationForm(component) {
  return ConfigurationForms[component];
}

class DetailComponentenDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: { value: '1', type: 'int' },
      key: '',
      value: '',
    };

    this.onSpecialConfigurationChange = this.onSpecialConfigurationChange.bind(this);
  }

  /**
   * Update the specialComponentConfig value for the given key or remove it if it is undefined
   *
   * @param   {String}  key   Config key
   * @param   {Mixed}   value Config value
   *
   * @return  void
   */
  onSpecialConfigurationChange(key, value) {
    const {
      editValueField,
      id,
      item
    } = this.props;

    const config = get(item, 'props.specialComponentConfig', []).filter((c) => get(c, 'key') !== key);

    const updated = isNil(value)
      ? config
      : [...config, { key, value }];

    editValueField(id, 'prop', { key: 'specialComponentConfig', value: updated });
  }

  componentDetailFields() {
    const {
      addComponent,
      item,
      items,
      editValueField,
      id,
      language,
      codeGroups,
      codes,
      fetchCodes,
      consultants,
      productGroups,
      ...rest
    } = this.props;
    const Component = getConfigurationForm(item.component);

    if (!_.isUndefined(Component)) {
      return (
        <Component
          addComponent={addComponent}
          codeGroups={codeGroups}
          codes={codes}
          consultants={consultants}
          editValueField={editValueField}
          fetchCodes={fetchCodes}
          id={id}
          item={item}
          items={items}
          language={language}
          onSpecialConfigurationChange={this.onSpecialConfigurationChange}
          productGroups={productGroups}
          {...rest}
        />
      );
    }

    console.warn(`Component --${item.component}-- not defined`);

    return null;
  }

  render() {
    const { id, item, language, editValueField, fetchCodes, workguide } = this.props;

    const components = [
      { value: 'Id', label: 'Eindeutige ID' },
      { value: 'BlockElement', label: 'Block Element (div)' },
      { value: 'Input', label: 'Eingabefeld' },
      { value: 'DateField', label: 'Datum' },
      { value: 'SingleSelect', label: 'Dropdown Einfach' },
      { value: 'Toggle', label: 'Boolean' },
      { value: 'MultiSelect', label: 'Dropdown Multi' },
      { value: 'CodeSelect', label: 'DropDown Finnova Code' },
      { value: 'AccountSelect', label: 'DropDown Konten des Kunden' },
      { value: 'ProductSelect', label: 'DropDown Produkte' },
      { value: 'ProductGroupSelect', label: 'DropDown Produkte mit Filter auf Produktgruppe' },
      { value: 'ConsultantSelect', label: 'Kundenberater Dropdown' },
      { value: 'Authorizations', label: 'Vollmachten' },
      { value: 'Contracts', label: 'Verträge des Kunden' },
      { value: 'ContractAccountSelection', label: 'Vertragskonten' },
      { value: 'PartnerContracts', label: 'Partnerübergreifenden Verträge' },
      { value: 'PersonalAndPowerOfAttorneyAccounts', label: 'Alle Konten pro Vollmacht' },
      { value: 'Check', label: 'Prüfpunkt' },
      { value: 'CustomerProducts', label: 'Produkte des Kunden' },
      { value: 'CustomerCards', label: 'Karten des Kunden' },
      { value: 'CustomerRelations', label: 'Kundenbeziehungen' },
      { value: 'CustomerEnforcementForm', label: 'Betreibungen des Kunden' },
      { value: 'OverwriteRecipient', label: 'Dropdown Mail Splitt' },
      { value: 'InfoBox', label: 'Meldung' },
      { value: 'CustomerSelection', label: 'Kundenselektion' },
      { value: 'EDossierUpload', label: 'EDossier Upload' },
      { value: 'EDossierCustomerDocuments', label: 'EDossier Kunden Dokumente Tabelle' },
      { value: 'UploadFile', label: 'Datei hochladen' },
      { value: 'CustomerAddresses', label: 'Auswahl Kundenadressen' },
      { value: 'FieldGroup', label: 'Feldgruppe' },
      { value: 'DiscussionParticipants', label: 'Gesprächsteilnehmer' },
      { value: 'CustomerContacts', label: 'Kontaktinformationen des Kunden' },
      { value: 'Mortgages', label: 'Hypothek mit Auftrags- und Rahmennummer von Krediten' },
      { value: 'PdfContinuationP3a', label: 'PDF Privor 3a Weiterführungsformular' },
      { value: 'PdfOpeningFZ', label: 'PDF Eröffnung Freizügigkeitskonto' },
      { value: 'PostActionCondition', label: 'Post Action Bedingung' },
      { value: 'AddressDogSearch', label: 'Address Dog Suche' },
      { value: 'AddressDogPlzSearch', label: 'Address Dog Suche für PLZ' },
      { value: 'AcceptDecline', label: 'Checkbox Guppe: Akzeptieren / Zurückweisen' },
      { value: 'CustomerLsv', label: 'LSV des Kunden' },
      { value: 'CustomerPortfolios', label: 'Portfolios des Kunden' },
      { value: 'FundinvestFunds', label: 'Fondsinvest Fonds' },
      { value: 'CamundaDataCheck', label: 'Camunda: Datenprüfung' },
      { value: 'CamundaCustomerCheck', label: 'Camunda: Prüfung Kunde' },
      { value: 'CamundaAddressChangeExistingAddress', label: 'Camunda: Adressänderunge - Bestehende Adresse' },
      { value: 'SecuritiesAccountFormalities', label: 'Camunda: Depoteröffnung - Formalitäten' },
      { value: 'ActivitySubStatus', label: 'Substatus Aktivität' },
      { value: 'CustomerFinancingTranchesBalancing', label: 'Saldierung von Finanzierungen' },
      { value: 'SwiftClearingSearch', label: 'Suche nach Swift Clearing' },
      { value: 'CustomerAccountPositions', label: 'Valoren pro Depot' },
      { value: 'StaticHtmlContent', label: 'Statischer HTML Inhalt' },
      // Custom Workguides / forms
      { value: 'ActivityForm', label: 'Formular "Neue Notiz erstellen"' },
      { value: 'AirlockSuspensionForm', label: 'Formular "Airlock sperren/entsperren"' },
      { value: 'AllowedRegionForm', label: 'Formular "Geoblocking mutieren"' },
      { value: 'BaseContractOpening', label: 'Formular "Basisvertrag erstellen"' },
      { value: 'CardClaimForm', label: 'Formular "Schadenfall Karten bearbeiten"' },
      { value: 'CardLimitForm', label: 'Formular "Kartenlimiten mutieren"' },
      { value: 'CardLimitFormOld', label: 'Formular "Kartenlimiten mutieren" (alt)' },
      { value: 'CardSuspensionForm', label: 'Formular "Karte Entsperren / Sperren"' },
      { value: 'ContactlessForm', label: 'Formular "Kontaktlos aktivieren"' },
      { value: 'DmcCardForm', label: 'Formular "Neubestellung DMC"' },
      { value: 'DmcCardFormOld', label: 'Formular "Neubestellung DMC" (Alt)' },
      { value: 'EcommerceForm', label: 'Formular "eCommerce aktivieren"' },
      { value: 'LeadForm', label: 'Neuer Lead erstellen' },
      { value: 'LeadScore', label: 'Lead Score berechnen' },
      { value: 'MisCustomerTransferForm', label: 'Formular "MIS Kundenüberführung G2"' },
      { value: 'NaturalPersonForm', label: 'Formular "Kunde ohne Konto/Depot/Tresorfach eröffnen"' },
      { value: 'EditCustomerForm', label: 'Formular "Kundendaten aktualisieren"' },
      { value: 'PinLetterForm', label: 'Formular "Pin nachbestellen"' },
      { value: 'RemoveCardForm', label: 'Formular "Karte löschen"' },
      { value: 'InterestForm', label: 'Formular "Interest erstellen"' },
      { value: 'RelationForm', label: 'Formular "Relation erstellen"' },
      { value: 'ReplacementForm', label: 'Formular "Ersatzbestellung"' },
      { value: 'ReplacementFormOld', label: 'Formular "Ersatzbestellung" (alt)' },
      { value: 'SecuritiesAccountOpeningForm', label: 'Formular "Depoteröffnung"' },
      { value: 'SecuritiesAccountUnlockForm', label: 'Formular "Depotsperrung aufheben "' },
      { value: 'CheckOnboardingForm', label: 'Formular "Manuelle Prüfung online Onboarding (KUBE)"' },
      { value: 'ValidateOnboardingForm', label: 'Formular "Manuelle Prüfung online Onboarding (QMS)"' },
      { value: 'ClassificationForm', label: 'Formular "Steuerprofil Erfassung Klassifikation"' },
      { value: 'OnboardingCardOrder', label: 'Formular "Neubestellung Debit Master Card"' },
      { value: 'OnboardingCardKubeEdit', label: 'Formular "Neubestellung Debit Master Card: Nachbearbeitung KUBE"' },
      { value: 'CreateIdentificationDocument', label: 'Formular "Identifikationsdokument aktualisieren"' },
      { value: 'IdentificationDocumentFourEyeConfirmation', label: 'Formular "4-Augen-Kontrolle Identifikationsdokument (QMS)"' },
      { value: 'IdentificationDocumentKubeEdit', label: 'Formular "Identifikationsdokument nachbearbeiten (KUBE)"' },
      { value: 'SelfDeclarationAiaFatca', label: 'Formular "Selbstdeklaration AIA / FATCA"' },
      { value: 'ContactInformationForm', label: 'Formular "Kontaktinformationen bearbeiten"' },
      { value: 'SetAdvisorWorkguidePrivateCreditCardOrder', label: 'Formular "Set Advisor Modul Kreditkarte eröffnen"' },
      { value: 'SetAdvisorWorkguidePrivateCreditCardOrderWithoutModule', label: 'Formular "Bestellung Kreditkarte (ohne Modul)"' },
      { value: 'SetAdvisorWorkguidePrivateCreditCardApproval', label: 'Formular "Set Advisor Bewilligung Kreditkartenlimite"' },
      { value: 'SetAdvisorWorkguidePrivateCreditCardCanceled', label: 'Formular "Set Advisor Kreditkartenlimite abgelehnt"' },
      { value: 'SetAdvisorWorkguidePrivateCreditCardCctTool', label: 'Formular "Set Advisor Bestellung Kreditkarte in CCT"' },
      { value: 'SetAdvisorWorkguidePrivateCreditCardKubeEdit', label: 'Formular "Set Advisor Bestellung Kreditkarte Nachbearbeitung KUBE"' },
      { value: 'SetAdvisorWorkguideOpenModuleWorld', label: 'Formular "Set Advisor Modul Welt eröffnen"' },
      { value: 'SetAdvisorWorkguideOpenAccount', label: 'Formular "Set Advisor Konto eröffnen"' },
      { value: 'SetAdvisorWorkguideOpenModulePaper', label: 'Formular "Set Advisor Modul Papier eröffnen"' },
      { value: 'SetAdvisorWorkguideNewLilaSet', label: 'Formular "Set Advisor Neues Lila Set eröffnen"' },
      { value: 'SetAdvisorWorkguideDissolveLilaSet', label: 'Formular "Set Advisor Set & Module aufheben"' },
      { value: 'SetAdvisorWorkguidePrivateDispatchRules', label: 'Formular "Set Advisor: eAvisierung mutieren' },
      { value: 'SetAdvisorWorkguideBackofficeDispatchRules', label: 'Formular "Set Advisor: eAvisierung backoffice' },
      { value: 'SetAdvisorWorkguidePrivateEbankingContractOrder', label: 'Formular "Set Advisor: E-Banking Vertrag eröffnen' },
      { value: 'SetAdvisorWorkguidePrivateEbankingContractKubeEdit', label: 'Formular "Set Advisor: E-Banking Vertrag Nachbearbeitung KUBE' },
      { value: 'CamundaAddressChangeProcesses', label: 'Camunda: Check for running address change processes' },
      { value: 'SetAdvisorConnectAccountsToContract', label: 'Formular "Konto an E-Banking-Vertrag hinzufügen / entfernen"' },
      { value: 'LilaSetWorkguideOpenAccount', label: 'Formular "Freemium Lila Set Konto eröffnen' },
      { value: 'LilaSetWorkguideOpenSet', label: 'Formular "Freemium Lila Set eröffnen' },
      {
        value: 'SetAdvisorConnectAccountsToContractEditDaz',
        label: 'Formular "Konto an eBanking Vertrag hinzufügen / entfernen: Nachbearbeitung DAZ"'
      },
      {
        value: 'SetAdvisorWorkguidePrivateEbankingContractViaMobileApp',
        label: 'Formular "Set Advisor: Manuelle Prüfung Bestellung E-Banking-Vertrag über Mobile App'
      },
      {
        value: 'SetAdvisorWorkguideSetAdvisorWorkguidePrivateEbankingContractReactivate',
        label: 'Formular "Set Advisor: Manuelle Prüfung E-Banking Vertrag reaktivieren'
      },
      {
        value: 'WithdrawalLimitCancellationForm',
        label: 'Formular "Rückzugslimiten-Kündigung PRIVOR Vorsorgekonto 3a erfassen"'
      },
      {
        value: 'OpenPrivorRetirementSavingsAccountDepositForm',
        label: 'Formular "Privor Vorsorgekonto/depot 3a eröffnen"'
      },
      {
        value: 'CreateCoverLetterForm',
        label: 'Formular "Sps Beleitschreiben erstellen"'
      },
    ];

    const componentOptions = components.map((c) => ({ value: c.value, label: `${c.label} (${c.value})` }));

    return (
      <div>
        <Form horizontal>
          <FormGroup controlId="id">
            <Col componentClass={ControlLabel} sm={3}>
              ID
            </Col>
            <Col sm={9}>
              <FormControl
                name={`${id}.id`}
                onChange={(ev) => editValueField(id, 'id', _.get(ev, 'target.value'))}
                type="text"
                value={item.props.id}
                disabled
              />
            </Col>
          </FormGroup>
          <FormGroup controlId="fieldName">
            <Col componentClass={ControlLabel} sm={3}>
              Feldname
            </Col>
            <Col sm={9}>
              <FormControl
                name={`${id}.formFieldTitle`}
                onChange={editValueField.bind(this)}
                type="text"
                value={item.props.title[language]}
              />
            </Col>
          </FormGroup>

          <FormGroup controlId="fieldType">
            <Col componentClass={ControlLabel} sm={3}>
              Feld Typ
            </Col>
            <Col sm={9}>
              <Select
                placeholder="Bitte Feldtyp auswählen"
                clearable={false}
                name={`components.${id}.component`}
                options={componentOptions}
                value={item.component}
                onChange={(selectedOption) => editValueField(id, 'component', selectedOption)}
              />
            </Col>
          </FormGroup>

          <FormGroup controlId="fieldType">
            <Col componentClass={ControlLabel} sm={3}>
              Grösse
              {' siehe ('}
              <a
                href="https://www.w3schools.com/bootstrap4/bootstrap_grid_basic.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bootstrap
              </a>
              )
            </Col>
            <Col sm={9} style={{ paddingLeft: '30px' }}>
              <ComponentSize
                component={item}
                id={id}
                editValueField={editValueField}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              Gesperrt
            </Col>
            <Col sm={9} style={{ paddingTop: '10px' }}>
              <Toggle
                checked={_.get(item, 'props.disabled') === undefined ? false : item.props.disabled}
                onChange={() => editValueField(id, 'disabled')}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              Pflichtfeld
            </Col>
            <Col sm={9} style={{ paddingTop: '10px' }}>
              <Toggle
                checked={_.get(item, 'props.required') === undefined ? false : item.props.required}
                onChange={() => editValueField(id, 'required')}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              Ausblenden
            </Col>
            <Col sm={9} style={{ paddingTop: '10px' }}>
              <Toggle
                checked={_.get(item, 'props.hidden') === undefined ? false : item.props.hidden}
                onChange={() => editValueField(id, 'hidden')}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              Verstecktes Kontrollfeld
            </Col>
            <Col sm={9} style={{ paddingTop: '10px' }}>
              <Toggle
                checked={_.get(item, 'props.controlValue') === undefined ? false : item.props.controlValue}
                onChange={() => editValueField(id, 'controlValue')}
              />
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              In Email / Notiz ausblenden
            </Col>
            <Col sm={9} style={{ paddingTop: '10px' }}>
              <Toggle
                checked={_.get(item, 'props.hideInMail') === undefined ? false : item.props.hideInMail}
                onChange={() => editValueField(id, 'hideInMail')}
              />
            </Col>
          </FormGroup>

          {this.componentDetailFields()}

          <FormGroup>
            <Col componentClass={ControlLabel} sm={3}>
              Validierungen
            </Col>
            <Col sm={9} style={{ paddingTop: '10px' }}>
              <FieldValidations
                component={item}
                validations={_.get(item, 'props.validations', {})}
                onChange={(validations) => editValueField(id, 'validations', validations)}
              />
            </Col>
          </FormGroup>

          <h4>Abhängikeiten</h4>
          <DependencyList
            target={item}
            dependencies={item.dependencies}
            onChange={(value) => editValueField(id, 'dependencies', value)}
            fetchCodes={fetchCodes}
          />
        </Form>
      </div>
    );
  }
}

DetailComponentenDetails.propTypes = {
  id: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  editValueField: PropTypes.func.isRequired,
  codeGroups: PropTypes.array,
  fetchCodes: PropTypes.func,
};

DetailComponentenDetails.defaultProps = {
  codeGroups: [],
  fetchCodes: _.noop,
};

export default DetailComponentenDetails;
